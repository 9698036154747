<template>
    <div>
        <CRow>
            <CCol sm="12">
                <div>
                    <CCard>
                        <CCardHeader>
                            <slot name="header">
                                <CIcon name="cil-grid" /> {{ this.$t('pages.orders.unverifiedOrderList') }}
                            </slot>
                        </CCardHeader>
                        <CCardBody>
                            <CDataTable :items="listUnverifiedOrder"
                                        :fields="fields"
                                        hover
                                        striped
                                        border
                                        small
                                        fixed
                                        :loading="isLoadingUnverifiedOrder"
                                        :items-per-page="itemsPerPage"
                                        pagination>   
                                
                                <template #createdDate_text="{item}">
                                    <td class="py-2">                                        
                                        <label v-text="formatDate(item.createdDate)"/>
                                    </td>
                                </template>
                                <template #expectedDeliveryDate_text="{item}">
                                    <td class="py-2">                                        
                                        <label v-text="formatDate(item.createdDate)"/>
                                    </td>
                                </template>
                                <template #orders_detail_actions="{item}">
                                    <td class="py-2">
                                        <CButton color="primary"
                                                 variant="outline"
                                                 square
                                                 size="sm"
                                                 @click="onViewOrderDetail(item)">
                                            {{ $t('pages.orders.detail') }}
                                        </CButton>                                       
                                    </td>
                                </template>
                            </CDataTable>
                        </CCardBody>
                    </CCard>                    
                </div>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'

    export default {
        name: 'OrderVerification',
        data() {
            return {
                itemsPerPage: 10,
                warningModal: false,               
                fields: [
                    { key: 'userName', label: i18n.t('pages.orders.orderName') },
                    { key: 'id', label: i18n.t('pages.orders.orderCode') },                                  
                    { key: 'phone', label: i18n.t('common.phone') },
                    { key: 'address', label:  i18n.t('common.address') },
                    { key: 'createdDate_text', label:  i18n.t('pages.orders.orderDate') },
                    // { key: 'expectedDeliveryDate_text', label:  i18n.t('pages.orders.deliveryDate') },                            
                    {
                        key: 'orders_detail_actions',
                        label:  i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },       
        computed: {
            ...mapGetters('orders', ['isLoadingUnverifiedOrder', 'listUnverifiedOrder'])
        },
        methods: {  
            formatDate(date) {             
                if(date === undefined)
                    return '';                
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');               
            },                    
            onViewOrderDetail(item) {
               
                this.$router.push('/orders/verifieddetail/' + item.id);
            },
        },
        mounted() { 
            this.$store.dispatch("orders/getUnverifiedOrders");            
        }
    }
</script>